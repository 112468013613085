import React from 'react';

import { Section as SectionElement } from './index.style';

interface PropsTypes { 
    backgroundColor?: string | undefined; // div background color
    children: React.ReactNode; // allow to pass children to this React Component 
    backgroundImage?: string | undefined; // images
    backgroundImageFallback?: string | undefined;
    backgroundRadial?: Array<string> | undefined // gradient from center with center first item of tab
}

const Section = (props: PropsTypes) => {
    return(
        <SectionElement className="section" {...props}> {/* {...props} simplify: backgroundColor={props.backgroundColor}, etc... */}
            {props.children}
        </SectionElement>
    );
}

export default Section;