import React from 'react';
import styled from 'styled-components';

import bg from '/Users/amb/Desktop/sly/Lord/src/Assets/img/SALLY-LOGO-ORANGE.png';
import bg2 from './SALLY-LOGO-ORANGE.png';


interface PropsTypes {
    fill?: string | undefined
}

const Link = styled.a`
    @media (max-width: 400px){
        width: 70%;
    }
`;

export default (props: PropsTypes) => (
    <Link href="/">
    

    <img src={bg2} height='100px' width='100px' />
    </Link>
);




/*

    <svg width="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 200">
            <path fill={props.fill ? props.fill : "#fff"} d="M148.1 27H57.5L18.4 173h114.5l12.1-45.3h-23.8zM295.7 27h-92.9c-14.4 0-24.6 7.9-28.4 22.4l-27.1 101.1c-4 15-.2 22.4 16.4 22.4h92.9c14.6 0 24.7-8.3 28.4-22.4l27.1-101.1c4.6-17.2-2.6-22.4-16.4-22.4zm-63.8 105.1H210l17.1-63.9H249l-17.1 63.9zM427.8 108.6l.1-.3c10.2-2.4 18.4-8.2 22.3-22.4l9.7-36.3c4.1-15.2-2.4-22.4-16.4-22.4H328.1L289 173h58l8-29.9h22l-8 29.9h58l12.1-45.2c2.4-9.5-2.4-18.1-11.3-19.2zm-36.1-21h-21.9l6.2-23.2h21.9l-6.2 23.2zM591.6 27H475.8l-39.1 145.8h115.6c14.7 0 24.8-8 28.7-22.6l27.1-101.1c4.1-14.9-.4-22.1-16.5-22.1zM528 131.4h-21.9L523 68.1h21.9L528 131.4zM738.8 127.7l4.2-15.8h23.9l6.4-23.5h-24l4.4-16.1h23.8L789.6 27H675.2L636 173h114.6l12.1-45.3zM874.4 88.5l4.3-16.2H905L917.1 27h-95.9c-14 0-24.2 6.7-28.4 22.4l-16.6 61.7h26.3l-4.5 16.6h-26.3l-12.1 45.2h95.7c14.7 0 24.9-9.1 28.4-22.4l16.6-62h-25.9zM1059.8 49.5c4.1-15.5-.6-22.4-16.4-22.4H928L889 173h58l9-33.7h57.1c15.5 0 25.2-8.9 28.8-22.4l17.9-67.4zm-70.7 47.8h-21.9l6.7-25h21.9l-6.7 25zM1138.6 127.7l4.2-15.8h24l6.3-23.5h-23.9l4.2-16.1h23.9l12.2-45.3H1075l-39.1 146h114.4l12.1-45.3zM1298.3 108.6l.1-.3c10.2-2.4 18.4-8.2 22.3-22.4l9.7-36.3c4.1-15.2-2.4-22.4-16.4-22.4h-115.4L1159.5 173h58l8-29.9h21.9l-8 29.9h58l12.1-45.2c2.4-9.5-2.4-18.1-11.2-19.2zm-36.1-21h-21.9l6.2-23.2h21.9l-6.2 23.2zM1460.7 27h-92.8c-15.6 0-25.1 9.8-28.4 22.4l-33.1 123.4h57.8l4.8-17.7h21.9l-4.8 17.7h58.3l33.1-123.4c3.4-12.7-2.8-22.4-16.8-22.4zm-54.3 70.9h-21.9l6.9-25.7h21.9l-6.9 25.7zM1570 27l-5.8 21.6-20-21.6h-50.7l-39.1 146h54.6l8.2-30.9h22l-8.4 30.9h54.4l39-146zM1708.7 127.7l38.7-55.4 12.1-45.3h-125.2l-12.1 45.3h23.7l-38.6 55.4-12.1 45.3h125.3l12.1-45.3zM1883.9 27H1791c-15.6 0-25.1 9.8-28.4 22.4l-33.1 123.4h57.8l4.8-17.7h21.9l-4.8 17.7h58.3l33.1-123.4c3.5-12.7-2.7-22.4-16.7-22.4zm-54.4 70.9h-21.9l6.9-25.7h21.9l-6.9 25.7z"/>
        </svg>


*/