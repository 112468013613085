import React, { Component } from 'react';
import { Route, Link,Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import './App.css';

import App2 from './App';
import cover from './Assets/img/cover.jpg';
import Secret from './Secret/Accueil'



class App extends Component {
  render() {
    return (
      <div>
       <Switch> 
        <Route exact path="/"  component={App2}/>
        <Route path="/125420222" exact component={Secret}/>
        <Route render={() => <Redirect to={{pathname: "/"}} />} />
        </Switch>

      </div>
    );
  }
}

export default App;