import React, { useState, createRef, useEffect } from 'react';
import { Container, ResponsiveEmbed, Row, Col } from 'react-bootstrap';
import Image from '../../components/Image/index';

import BackgroundWebp from '../../Assets/img/VIDEO/LORD-WEB-VIDEO-SECTION-BG.webp';
//import BackgroundPng from '../../Assets/img/VIDEO/LORD-WEB-VIDEO-SECTION-BG.png';
import BackgroundPng from '../../Assets/img/VIDEO/background.png';
const BackgroundJPF = require('../../Assets/img/VIDEO/LORD-WEB-VIDEO-SECTION-BG.jp2'); 



const handleType = (ev: React.SyntheticEvent<HTMLEmbedElement, Event>) => {
    ev.currentTarget.type = "video/mp4";
}

interface Vid {
    id: string
    alt: string
}

const Video = () => {
    /* 
    - Video set-up
    */
    const videos = {
        default: { id: "JFYGZ31xIuc", alt: "MALHONNÊTE" },
        gallery: [
            { id: "Vhque-szS9E", alt: "Sally - PARTOUT OÙ JE VAIS" },
            { id: "I9F_6hChX-M", alt: "Sally - SHOOT" },
            { id: "WmBq1BLxhko", alt: "Sally - TOUT ROULE " }
            
        ],
        thumbnail: "hqdefault"
    }
    const [video,setVideo] = useState(videos.default);
    const [gallery,setGallery] = useState(videos.gallery);
    const embedVideo = (newVideo: Vid) => {
        gallery.splice(gallery.indexOf(newVideo),1); // removing newVideo from gallery
        setGallery( gallery.concat([video]) ); // adding currentVideo to gallery
        setVideo(newVideo); // setting new video
    }

    /* 
    - Lazy Loading Youtube to gain performance 
    */
    const [showVideo, setShowVideo] = useState(true);
    const container = createRef<HTMLDivElement>();
    const onVideoIntersection = (entries: IntersectionObserverEntry[]) => {
        if (!entries || entries.length <= 0) {
            return;
        }
        if (entries[0].isIntersecting) {
            setShowVideo(true);
            videoObserver.disconnect();
        }
    }
    const videoObserver = new IntersectionObserver(onVideoIntersection, {
        rootMargin: '100px 0px', // The rootMargin is used to add a bounding box around the element to compute the intersections
        threshold: 0.25 // threshold indicates at what percentage of the target’s visibility the observer’s callback should be executed.
    });
    useEffect(() => { // Lazy Load Youtube Video
        if (window && 'IntersectionObserver' in window) {
            if (container && container.current) {
                videoObserver.observe(container.current);
            }
        } else { // if old browser, load video directly
            setShowVideo(true);
        }
    }, [container]); // skip useEffect if previous [container] == new [container]

    return(
        <div style={{width: "100%", height: "100%", display: "flex", alignItems: "center"}}>
            {/* Container taking full width for background */}

            <Image 
                src={BackgroundPng} 
                fallback={BackgroundPng} 
                fallbackPng={BackgroundPng}
                isBackground 
                className="w-100 h-100 position-absolute"
                alt="Video background section"
                />
            <Container className="p-5 my-5">
                {/* Make video responsive */}
                <ResponsiveEmbed aspectRatio="16by9" > 
                    {
                        showVideo ? 
                            <iframe 
                            title={video.id} width="560"  height="315" 
                            src={`https://www.youtube.com/embed/${video.id}`} frameBorder="0" 
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                            allowFullScreen>
                        </iframe>
                        : <h3>Video loading...</h3>
                    }    
                </ResponsiveEmbed>

                {/* Image Gallery */}
                <Row className="mx-1 mt-4">
                    {gallery.map((video: Vid)=>{
                        return(
                            <Col className="p-0">
                                <button 
                                    aria-label={video.alt}
                                    onClick={() => embedVideo(video)} // replace video
                                    style={{width: "inherit", border: "unset", background: "transparent"}} // removing default button styles
                                >
                                    <img 
                                        style={{width:"inherit"}} 
                                        src={`https://img.youtube.com/vi/${video.id}/hqdefault.jpg`}
                                        alt={video.alt}
                                    />
                                </button>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </div>
        
    );
}

export default Video;