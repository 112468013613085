import React, {useEffect} from "react";
import { Title, Link } from "./index.style";

// Quicklink
import { prefetch } from 'quicklink';

// Background
import Image from '../../components/Image/index'
import BackgroundWebp from '../../Assets/img/BOUTIQUE/LORD-WEB-SHOP-TEXTURE-ASSET.webp';
//import BackgroundPng from '../../Assets/img/BOUTIQUE/LORD-WEB-SHOP-TEXTURE-ASSET.png';
import BackgroundPng from '../../Assets/img/BOUTIQUE/background.png';
import Footer from '../../components/Footer';


const BackgroundJPF = require('../../Assets/img/BOUTIQUE/LORD-WEB-SHOP-TEXTURE-ASSET.jp2'); 

export default () => {
    useEffect(()=>{
        prefetch(process.env.REACT_APP_SHOP);
    });
    return(
        <div style={{width: "100%", height: "100%", display: "flex", alignItems: "center"}}>
        {/* Container taking full width for background */}

            <Image 
                src={BackgroundPng} 
                fallback={BackgroundJPF}
                fallbackPng={BackgroundPng}
                isBackground 
                className="w-100 h-100 position-absolute"
                alt="Shop background section"
                />
            <Title style={{zIndex: 1, position: "relative"}} className="mx-auto">
                <Link href={process.env.REACT_APP_SHOP} target="_blank" rel="noopener noreferrer">
                    SHOP.SWEETCHILISALLY.COM 

                </Link>
            </Title>
            <Footer />
        </div>
    );
};