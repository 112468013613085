import styled from "styled-components";

export const Credit = styled.section`
  position: absolute;
  bottom: 0;
  right: 30px;
  white-space: nowrap;
  display: flex;

  @media only screen and (max-width: 768px){
    display: none;
  }
`;

export const Socials = styled.section`
  position: absolute;
  bottom: 0;
  left: 30px;
  width: fit-content;

  @media only screen and (max-width: 768px){
    align: center;
    left: calc(50% + 20px);
    transform: translateX(-50%);
    bottom: 30px;
  }
`;

export const Contact = styled.section`

  @media only screen and (max-width: 768px){
    bottom: -50px;
    position: absolute;
    left: calc(50% - 13px);
    transform: translateX(-50%);
  }
`;
