import React, { useState } from "react";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import { Bar, NavbarMenu, NavbarTitle, NavbarButton, NavbarToggle, NavbarToggleClose } from "./index.style";
import Logo from "./logo";

const NavBar = () => {
    const [opened, setOpened] = useState(false);
    return(
        <Navbar bg="transparent" expand="md" fixed="top" variant="dark">
            <Logo/>
           
            <Navbar.Toggle 
                as={!opened ? NavbarToggle : NavbarToggleClose} 
                onClick={() => setOpened(!opened)}
                height={50} 
                aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <NavbarMenu>
                    <NavbarButton href="#home">MUSIQUE</NavbarButton>
                    <NavbarButton href="#video">VIDÉOS</NavbarButton>
                    <NavbarButton href={process.env.REACT_APP_SHOP} target="_blank" rel="noopener noreferrer">SHOP</NavbarButton>
                </NavbarMenu>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default NavBar

/*
shop.sally.com

process.env.REACT_APP_SHOP
                    <NavbarButton href={process.env.REACT_APP_SHOP} target="_blank" rel="noopener noreferrer">BOUTIQUE</NavbarButton>
 <img src={Diamond} />
*/