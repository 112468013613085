import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FaTwitter, FaInstagram, FaFacebookSquare } from 'react-icons/fa';
import { Credit, Socials, Contact } from './index.style';

const Footer = () => {
  return (
    <div style={{ 
      position: "absolute", 
      bottom: "50px", 
      left: "0", 
      display: "flex", 
      justifyContent: "space-between" ,
      width: "100%",
    }}>
      <Socials>
        <Container>
          <Row>
            <Col
              as="button"
              color="white"
              onClick={() => { window.open("https://twitter.com/sweetchilisally", "_blank") }}
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "white",
              }}
            >
              <FaTwitter size="30px" />
            </Col>
            <Col style={{ width: "10px" }} />
            <Col
              as="button"
              color="white"
              onClick={() => { window.open("https://www.facebook.com/SallySweetChili/", "_blank") }}
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "white",
              }}
            >
              <FaFacebookSquare size="30px"  />
            </Col>
            <Col style={{ width: "10px" }} />
            <Col
              as="button"
              onClick={() => { window.open("http://instagram.com/sweetchilisally", "_blank") }}
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "white",
              }}
            >
              <FaInstagram size="30px"  />
            </Col>
            <Col style={{ width: "10px" }} />
            <Contact>
              <Button variant="link" style={{ color: "white", fontWeight: "bold" }} href="mailto:contact@paramour.eu">
                CONTACT
              </Button>
            </Contact>
          </Row>
        </Container>
      </Socials>
      <Credit>
        <p style={{ color: "#ff620f", fontSize: "15px", fontWeight: "normal", whiteSpace: "nowrap" }}>
          SALLY 2022 (C) 
        </p>
        <p style={{ color: "#ff620f", fontSize: "15px", fontWeight: "bold", whiteSpace: "nowrap", marginLeft: "5px" }}>
          SYMBIOSE SAS
        </p> 
        <p style={{ color: "#ff620f", fontSize: "15px", fontWeight: "normal", whiteSpace: "nowrap", marginLeft: "5px" }}>
          (C)
        </p>
      </Credit>
    </div>
  );
}

export default Footer;