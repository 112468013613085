import React, { useState, useContext } from 'react';

// Style
import './App.css';
import theme from './components/theme';
import { ThemeProvider, createGlobalStyle, ThemeContext } from 'styled-components';

// Components
import Navbar from './components/NavBar';
import Section from './components/Section/index'
import Accueil from './components/Accueil/Accueil';
import Tracklist from './screens/tracklist/index';
import Video from './screens/video';
import Concerts from './screens/concerts';
import Shop from './screens/shop';

import ReactFullpage from '@fullpage/react-fullpage';

// Assets
import Image from './components/Image/index';
import BackgroundWebp from './Assets/img/CONCERTS/LORD-WEB-RADAR-MAP-ASSET.webp';
import BackgroundPng from './Assets/img/CONCERTS/LORD-WEB-RADAR-MAP-ASSET.png';
const BackgroundJPF = require('./Assets/img/CONCERTS/LORD-WEB-RADAR-MAP-ASSET.jp2');

const GlobalStyle = createGlobalStyle`
	body{
		color: ${props => props.theme.colors.white};
		font-family: allotrope;
		font-display: swap; /* Display desktop font while loading for custom font test*/
	}
`;

const App = () => {
	const [menuOpened, setMenuOpened] = useState();
	const themeContext = useContext(ThemeContext);
	return (
		<ThemeProvider theme={theme}>
			<GlobalStyle />

			<Navbar />
			<ReactFullpage
				scrollingSpeed={1000}
				anchors={['home','video','shop']}
				scrollOverflow
				render={({ state, fullpageApi }) => {
					return (
						<ReactFullpage.Wrapper >
							
							<Section>
								<Accueil />
							</Section>
							<Section >
								<Video />
							</Section>
						
							{/* Background Radial: gradient from center with center first item of tab */}
							<Section >
								<Shop/>
							</Section>

						</ReactFullpage.Wrapper>
					);
				}}
			/>
		</ThemeProvider>
	);
}

export default App;


/*

	<Section>
							<FootBar/>
							</Section>

	<Section >
								<Video />
							</Section>

	anchors={['homee','video','video','video','shop']}


<Section backgroundColor={theme.colors.darkGrey}>
								<Tracklist />
							</Section>

								<Section>
								<div style={{width: "100%", height: "100%", display: "flex", alignItems: "center"}}>
									<Image 
										src={BackgroundWebp} 
										fallback={BackgroundJPF} 
										fallbackPng={BackgroundPng}
										isBackground 
										className="w-100 h-100 position-absolute"
										alt="Concerts background section"
									/>
									<Concerts/>
								</div>
							</Section>

							*/