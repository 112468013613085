import React, { useState } from 'react';
import BtnHover from "../../Assets/SALLY_PRISONNIRE_BUTTON_HOVER.svg";
import Btn from "../../Assets/SALLY_PRISONNIRE_BUTTON.svg";

export default () => {
  const [isIn, setIsIn] = useState(false);
  return (
    <div
      onMouseEnter={() => setIsIn(true)}
      onMouseLeave={() => setIsIn(false)}
      style={{
        width: "fit-content",
        height: "fit-content",
        transition: "0.4s",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        margin: "auto",
      }}
    >
      <img
        width="100%"
        src={isIn ? BtnHover : Btn}
        alt="Sally-prisonnier-button"
        style={{
          transition: "0.4s",
        }}
      />
    </div>
  )
};
