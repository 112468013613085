import React from "react";

import ResponsiveEmbed from 'react-bootstrap/ResponsiveEmbed';

import {Picture, Img} from './index.style'

interface Size {
    width: string
    heigth: string
}

interface ImageProps {
    className?: string | undefined; // Styled Components relies on class name to style
    src: string; // Webp or next gen image
    fallback: string; // Fallback on jp2
    fallbackPng: string // Fallback on png
    type?: string; // type of "src"
    alt?: string | undefined; // seo related
    isBackground?: boolean
    size?: Size
    style?: React.CSSProperties
}

const Image = ({className, src, fallback, fallbackPng, type = 'image/webp', alt = "", isBackground, size, style}: ImageProps) => {
    return (
        <Picture className={className}>
          <source srcSet={src} type={type} />
          {
              fallback ?
              <source srcSet={fallback} type="image/jp2"/>
              :
              undefined
          }
          <Img 
            isBackground
            src={fallbackPng} 
            alt={alt} 
            { ...( isBackground ? {width: "100%", height: "100%"} : {width: size?.width, height: size?.heigth} ) } 
            { ...( style && { style })}
            />
        </Picture>
    );
}

export default Image