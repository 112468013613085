import React, { Component } from 'react'
import LazyLoad from 'react-lazyload';

import LoRways from './lo-rways';

// Background
import Image from '../../components/Image/index'
import coverWebp from "../../Assets/img/MUSIQUE/COVER/LEDTV2-FRONT-COVER.webp"
import coverPng from "../../Assets/img/MUSIQUE/COVER/LEDTV2-FRONT-COVER.png"
import BackgroundWebp from "../../Assets/img/MUSIQUE/LORD-WEB-BLUE-WORLDMAP-ASSET.webp";
//import BackgroundPng from "../../Assets/img/MUSIQUE/LORD-WEB-BLUE-WORLDMAP-ASSET.png";
import BackgroundPng from "../../Assets/img/MUSIQUE/background.png";
//const cover = require("../../Assets/img/MUSIQUE/COVER/LEDTV2-FRONT-COVER.jp2");
const cover = require("../../Assets/img/MUSIQUE/COVER/cover.jpg");
const BackgroundJPF = require("../../Assets/img/MUSIQUE/LORD-WEB-BLUE-WORLDMAP-ASSET.jp2");


class Accueil extends Component {
	render() {
		return (
			<div style={{width: "100%", height: "100%", display: "flex", alignItems: "center"}}>
            {/* Container taking full width for background */}

                <Image 
                    src={BackgroundPng} 
					fallback={BackgroundPng}
					fallbackPng={BackgroundPng} 
                    isBackground 
                    className="w-100 h-100 position-absolute"
                    alt="Tracklist background section"
                    />
				<div style={{zIndex: 2, width: "100%", textAlign: "center"}}>
					<a target="_blank" rel="noopener noreferrer nofollow" href={process.env.REACT_APP_LNK_TO}>
						<LazyLoad>
							<Image 
								src={cover} 
								fallback={cover} 
								fallbackPng={cover}
								alt="Sally-album-cover" 
								size={{width:"30px", heigth:"30px"}}
								style={{width: "60vmin", height: "auto"}}
								/>
						</LazyLoad>	
						<br/>	
						<br/>		
						<LoRways/>
					</a>
				</div>
            </div>
		);
	}
}

export default Accueil;